//import { HttpHeaders } from '@angular/common/http';

//import { ServiceApiHelperService } from './service-api-helper.service';
import { Debug } from './service-api-debug-log';
import { XmlDomHelper } from './service-api-xml-dom-helper.worker'
import { BlobPlatformApiService } from '../platform-api/blob-platform-api.service';

export interface AzureStorageContainer {
  name: string;
  sas: string;
}


export interface AzureBlobItemMetadata {
  key: string;
  value: string;
}

export interface AzureBlobItem {
  id: number;
  name: string;
  lastModified: string;
  //secureUrl: string;

  metadata: AzureBlobItemMetadata[];
}

export interface AzureStorageBrokerContext {
  //accountName: string;
  //container: AzureStorageContainer;
  //apiHelper: ServiceApiHelperService;
  blobApi: BlobPlatformApiService;
}

export class AzureStorageBroker {
  //blobUri: string;
  //apiHelper: ServiceApiHelperService;
  //container: AzureStorageContainer;
  blobApi: BlobPlatformApiService;

  public static create(context: AzureStorageBrokerContext): AzureStorageBroker {
    const obj = new AzureStorageBroker();
    //obj.blobUri = 'https://' + context.accountName + '.blob.core.windows.net';
    //obj.container = context.container;
    //obj.apiHelper = context.apiHelper;
    obj.blobApi = context.blobApi;

    return obj;
  }

  getObjectPrefix(type: string): string {
    return type + "-";
  }

  getObjectName(type: string, id: number): string {
    return this.getObjectPrefix(type) + id.toString() + '.json';
  }

  getObjectIdFromName(type: string, name: string): number {
    const prefix = this.getObjectPrefix(type);
    name = name.replace(prefix, '');
    name = name.replace('.json', '');
    return parseInt(name);
  }

  public async getObjects(type: string): Promise<AzureBlobItem[]>
  {
    const prefix = this.getObjectPrefix(type);
    //const maxResults = 1000;
    //const url = this.blobUri + '/' + this.container.name + this.container.sas +
    //  '&comp=list&prefix=' + prefix + '&restype=container&include=metadata&maxresults'+maxResults;

    try {
      //const result = await this.apiHelper.getXmlResponseFromUrl(url);
      const result = await this.blobApi.list(prefix);

      const items = this.parseXmlToBlobItems(type, result);
      return Promise.resolve(items);
    }
    catch(err) {
      Debug.logO('getObjects: type=' + type, err);
      return Promise.reject(err);

    }
  }

  public async getObjectByName(name: string): Promise<object> {
    //const url = this.generateSecureUrlForBlob(name, this.container);
    //const result = await this.apiHelper.getObjectFromUrl(url);
    const result = await this.blobApi.getBlob(name);

    return Promise.resolve(result);
  }

  public async getObject(type: string, id: number): Promise<object>
  {
    const name = this.getObjectName(type, id);
    const obj = await this.getObjectByName(name);
    return Promise.resolve(obj);
  }

  public async storeObject(type: string, id: number, data: object, metadata?: AzureBlobItemMetadata[], contentType?: string): Promise<object>
  {
    const name = this.getObjectName(type, id);

    const result = this.blobApi.storeBlob(name, data, metadata, contentType);
    return Promise.resolve(result);

    /*
    const url = this.generateSecureUrlForBlob(name, this.container);
    if(contentType==null) {
      contentType = 'application/json';
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', contentType);
    headers = headers.set('x-ms-blob-type', 'BlockBlob')
    //headers = headers.set('x-ms-meta-dude', 'shariq');

    if(metadata!=null && metadata.length>0) {
      for(let m of metadata) {
        headers = headers.append('x-ms-meta-' + m.key.toString(), m.value.toString());
      }
    }
    //console.log('headers', headers);

    try {
      const result = await this.apiHelper.putObjectAtUrl(url, data, headers, true);
      //console.log('result', result);

      const items = this.parseXmlToBlobItems(type, result, this.container);
      if(items.length>0) {
        return Promise.resolve(items[0]);
      }
      else {
        return Promise.resolve(data);
      }
    }
    catch(err) {
      Debug.logO('storeObject', err);
      return Promise.reject(err);
    }
    */
  }

  public async deleteObject(type: string, id: number): Promise<any>
  {
    const name = this.getObjectName(type, id);
    const result = await this.blobApi.deleteBlob(name);
    return Promise.resolve(result);

    /*
    const url = this.generateSecureUrlForBlob(name, this.container);
    const result = await this.apiHelper.deleteObjectAtUrl(url);
    return Promise.resolve(result);
    */
  }



  //generateSecureUrlForBlob(blobName: string, container: AzureStorageContainer): string {
  //  return this.blobUri + '/' + container.name + '/' + blobName + container.sas;
  //}


  parseXmlToBlobItems(type: string, xml: string) : AzureBlobItem[] {
    //console.log('parsing', xml);
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, 'text/xml');
    const dom = new XmlDomHelper();
    const blobs = doc.getElementsByTagName('Blob');
    //console.log('blobs', blobs);
    const items: AzureBlobItem[] = [];

    for(let i=0; i<blobs.length; i++) {
      const b = blobs[i];

      const item = {} as AzureBlobItem;
      items.push(item);

      item.name = dom.findDomNodeText(b, 'Name');
      item.id = this.getObjectIdFromName(type, item.name);
      item.lastModified = dom.findDomNodeText(b, 'Properties/Last-Modified');
      //item.secureUrl = this.generateSecureUrlForBlob(item.name, container);

      let metadata = dom.findDomNode(b, 'Metadata');
      if(metadata!=null && metadata.children.length>0) {
        item.metadata = [];

        for(let m=0; m<metadata.children.length; m++) {
          let mNode: Element = metadata.children[m];
          //console.log('mNode: ', mNode);
          if(mNode!=null) {
            item.metadata.push({
              key: mNode.tagName,
              value: mNode.textContent
            });
          }
        }
      }


    }

    return items;
  }
}
