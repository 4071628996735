
import { AppVariables } from './app.config.model';
import { AppHelper } from './app-helper.worker';
import { CricketClubApiService } from './cricket-club-api.service';
import { Fixture, Team, User, UserRightsEnum, WeeklyDigestOptions } from './cricket-club-api.model';
import { Message, MailgunApiService } from '../mailgun-api/mailgun-api.service';
import { AuthenticationService } from '../../services/authentication-api/authentication-api.service';

export class NotificationHelper {
  private readonly cricketApi: CricketClubApiService;
  private readonly mailApi: MailgunApiService;
  private readonly auth_api: AuthenticationService;

  constructor(cricketApi: CricketClubApiService, mailApi: MailgunApiService, auth_api: AuthenticationService) {
    this.cricketApi = cricketApi;
    this.mailApi = mailApi;
    this.auth_api = auth_api;
  }

  public async sendWeekdlyDigestEmail(sender: User, options: WeeklyDigestOptions) {
    const venues = await this.cricketApi.getVenues();
    const users = await this.cricketApi.getUsers();

    let count = 0;
    let html = ''; // '<table>';
    const fixtures = await this.cricketApi.getFixtures();
    for(let f of fixtures) {
      count++;
      const v = venues.filter(venue => venue.id==f.venueId)[0];

      //html += '<tr><td style="padding-bottom:30px">';
      html += '<p><b>' + count + ') ' + f.opponent + '</b><br/>' +
        '@ ' + v.title + '<br/>' +
        'on ' + AppHelper.formatDateToString(f.date) + '</p>';

      if(f.notes!=null && f.notes.length>0) {
        html += '<b>Notes:</b> ' + f.notes;
      }

      if(f.isTeamFinal) {
        html += '<p><b>Team for this fixture has been announced.</b> For any changes or requests, please reach out to the skipper(s) directly.</p>';

        html += '<p><u>Team</u></p>';
        html += '<ol>'
        const team = await this.cricketApi.getTeam(f.teamId);
        const players = users.filter(u => team.selectedUserIdList.indexOf(u.id)>=0)
          .sort((a, b) => a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1);
        
        for(let u of players) {
          //const u = users.filter(user => user.id == uId)[0];

          html += ('<li>' + u.firstName + ' ' + u.lastName);
          if(team.keeperUserId == u.id && team.skipperUserId==u.id) {
            html += ' (Captain, Wicket Keeper)'; }
          else if(team.keeperUserId==u.id) {
            html += ' (Wicket Keeper)'; }
          else if (team.skipperUserId==u.id) {
            html+= ' (Captain)'; }

          html += '</li>';
        }
        html += '</ol>'
      }
      else {
        if(options.include_sign_up_list==true) {
          if(f.signedUserIdList.length==0) {
            html += '<p><u>Sign Ups:</u> No one has signed up for this fixture yet.</p>';
          }
          else {
            html += '<p><u>Sign Ups:</u></p><ol>';
  
            const players = users.filter(u => f.signedUserIdList.indexOf(u.id)>=0)
              .sort((a, b) => a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1);
  
            for(let u of players) {
              html += ('<li>' + u.firstName + ' ' + u.lastName + '</li>');
            }
            html += '</ol>'
          }
        }
      }
      //html += '</td></tr>';
    }
    //html += '</table>';

    console.log(options);
    //console.log(users);
    const user_list = users.filter(u =>
      (options.everyone==true) ||
      (options.administrators==true && this.auth_api.user_right_exists(u, UserRightsEnum.canEditFixture)) ||
      (options.paid_members==true && this.auth_api.user_right_exists(u, UserRightsEnum.isPaidMember)) || 
      (options.club_staff==true && this.auth_api.user_right_exists(u, UserRightsEnum.isClubStaff)) || 
      (options.high_priority_guests==true && this.auth_api.user_right_exists(u, UserRightsEnum.isHighPriorityGuest)) || 
      (options.playing_members==true && this.auth_api.user_right_exists(u, UserRightsEnum.canSignUpForFixture))
    );
    if(user_list.length==0) {
      user_list.push(this.auth_api.getCurrentUser());
    }

    const bccList = [] as string[];
    for(let u of user_list) {
      bccList.push(u.email);
    }

    console.log('bccList', bccList);

    let htmlBody = await this.cricketApi.getObjectFromUrlAsString(AppVariables.email_Weekly_Digest_TemplateName);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{club_name}}', AppVariables.clubShortName);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{club_url}}', AppVariables.clubUrl);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{club_contact_name}}', sender.firstName + ' ' + sender.lastName);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{club_contact_email}}', sender.email);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{weekly_digest_content}}', html);
    htmlBody = AppHelper.replaceAll(htmlBody, '{{senderName}}', sender.firstName + ' ' + sender.lastName);

    const m: Message = {
      subject: AppVariables.clubFullName + ': Fixtures and signups update',
      toList: [ sender.email ],
      bccList: bccList,
      fromEmail: sender.email,
      htmlBody: htmlBody
    };

    console.log('m', m);
    await this.mailApi.sendMessage(m);

  }

  public async sendFinalTeamEmail(fixture: Fixture, team: Team, sender: User) {

    let players: User[] = [];
    for(let userId of team.selectedUserIdList) {
      players.push(await this.cricketApi.getUser(userId));
    }
    players = players.sort((a,b) => {
      return (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName) ?
        -1 : 1
    });

    let playerList = '';

    const toList: string[] = [];
    for(let u of players) {
      toList.push(u.email);

      let str = ('<li>' + u.firstName + ' ' + u.lastName);
      if(team.keeperUserId == u.id && team.skipperUserId==u.id) {
        str += ' (Captain, Wicket Keeper)'; }
      else if(team.keeperUserId==u.id) {
        str += ' (Wicket Keeper)'; }
      else if (team.skipperUserId==u.id) {
        str+= ' (Captain)'; }

      playerList += str;
    }

    let htmlBody = await this.cricketApi.getObjectFromUrlAsString(AppVariables.email_Final_Team_TemplateName);
    htmlBody = htmlBody.replace('{{clubName}}', AppVariables.clubShortName);
    htmlBody = htmlBody.replace('{{opponentName}}', fixture.opponent);
    htmlBody = htmlBody.replace('{{fixtureDate}}', AppHelper.formatDateToString(fixture.date));


    const v = await this.cricketApi.getVenue(fixture.venueId);
    const venueName = v!=null? v.title : 'Unspecified venue';
    const venueAddress = v!=null ? v.address : 'Unspecified venue address';

    htmlBody = htmlBody.replace('{{venueName}}', venueName);
    htmlBody = htmlBody.replace('{{venueAddress}}', venueAddress);
//formatDateToTimeString
    htmlBody = htmlBody.replace('{{playerList}}', playerList);
    htmlBody = htmlBody.replace('{{senderName}}', sender.firstName + ' ' + sender.lastName);

    const m: Message = {
      subject: 'Team for ' + fixture.opponent + ' has been announced',
      toList: toList,
      fromEmail: sender.email,
      htmlBody: htmlBody
    };

    console.log('m', m);

    await this.mailApi.sendMessage(m);

  }



}
