import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { PlatformConfigurationApiService } from './platform-configuration-api.service';

@Injectable({
  providedIn: 'root'
})
export class BlobPlatformApiService {

  readonly blobUri: string;
  readonly default_resourceType = 'container';

  constructor(
    private config: PlatformConfigurationApiService,
    private http: ServiceApiHelperService
  ) {

    this.blobUri = this.config.platformUri + '/blobs';

  }

  getRequestHeaders(): HttpHeaders {
    const obj = {};
    obj[this.config.key_platform_appId] = this.config.appId;
    obj[this.config.key_platform_storageId] = this.config.storageId;

    const authHeader = 'Basic ' + window.btoa(JSON.stringify(obj));

    let headers = new HttpHeaders()
      .set('Authorization', authHeader);

    return headers;
  }

  public async getBlob(blobName: string): Promise<object> {
    const url = this.blobUri + "/" + blobName;
    const headers = this.getRequestHeaders();

    const result = await this.http.getObjectFromUrlWithHeaders(url, headers);
    //console.log('result', result);
    return Promise.resolve(result);
  }

  public async getBlobByPathName(blobPathName: string): Promise<object> {
    const url = this.blobUri + "/getBlobByPathName?blobPathName=" + blobPathName;
    //console.log('getting', url);
    const headers = this.getRequestHeaders();

    const result = await this.http.getObjectFromUrlWithHeaders(url, headers);
    //console.log('result', result);
    return Promise.resolve(result);
  }

  public async list(prefix?: string, resourceType?: string, includeMetadata?: boolean, maxResults?: number): Promise<string> {
    if(prefix==null) { prefix = ''; }
    if(resourceType==null) { resourceType = this.default_resourceType; }
    if(includeMetadata==null) { includeMetadata = true; }
    if(maxResults==null) { maxResults = 5000; }

    const url = this.blobUri + '/query?' +
      'comp=list&prefix=' + prefix +
      '&restype=' + resourceType +
      (includeMetadata==true ? '&include=metadata' : '') +
      '&maxresults=' + maxResults;

    //console.log('url', url);
    const headers = this.getRequestHeaders();

    const result = this.http.getXmlResponseFromUrl(url, headers);
    return Promise.resolve(result);
  }


  public async deleteBlob(blobName: string): Promise<any>
  {
    const headers = this.getRequestHeaders();

    const url = this.blobUri + "/delete?name=" + blobName;
    const result = await this.http.deleteObjectAtUrlWithHeaders(url, headers);
    return Promise.resolve(result);
  }

  public async getBlobUrl(blobName: string):Promise<string> {
    try {
      const headers = this.getRequestHeaders();

      const url = this.blobUri + "/getBlobUrl?name=" + blobName;
      const result = await this.http.getObjectFromUrlWithHeaders(url, headers) as {url: string};
      return Promise.resolve(result.url);
    }
    catch(err) {
      return Promise.reject(err);
    }
  }

  public async storeBlob(blobName: string, data: object, metadata?: AzureBlobItemMetadata[], contentType?: string): Promise<object>
  {
    if(contentType==null) { 'application/json' };

    let headers = this.getRequestHeaders();

    if(metadata!=null && metadata.length>0) {
      for(let m of metadata) {
        headers = headers.append('x-ms-meta-' + m.key.toString(), m.value.toString());
      }
    }
    //console.log('storeBlob.headers', headers);

    try {
      const url = this.blobUri + "/store?name=" + blobName;
      await this.http.postObjectToUrlWithHeaders(url, data, headers, false);
      return Promise.resolve(data);
    }
    catch(err) {
      return Promise.reject(err);
    }
  }

}


export interface AzureBlobItemMetadata {
  key: string;
  value: string;
}
