
export class Debug {

  public static log(message: any) {
    console.log(message);
  }

  public static logO(message: string, obj: any) {
    console.log(message, obj);
  }

}
