import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage';

import { EditObjectPropertiesPage } from './dialogs/edit-object-properties/edit-object-properties.page';
import { EditTeamPage } from './dialogs/edit-team/edit-team.page';
import { EditUserPage } from './dialogs/edit-user/edit-user.page';

@NgModule({
  declarations: [AppComponent,
    EditObjectPropertiesPage,
    EditTeamPage,
    EditUserPage
  ],
  entryComponents: [
    EditObjectPropertiesPage,
    EditTeamPage,
    EditUserPage
  ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    FormsModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: '__com_digix_pp_mobileapp',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
