import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import * as FormData from 'form-data';
import { HttpClient } from '@angular/common/http';


import { CricketClubApiService } from '../cricket-club-api/cricket-club-api.service';
import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { HttpProxyApiHelper } from '../service-api-helper/http-proxy-api-helper.worker';

export interface Message {
  subject: string;
  htmlBody: string;
  fromEmail?: string;

  toList: string[];
  ccList?: string[];
  bccList?: string[];
}

export interface MailgunApiResponse {
  id: string;
  message: string;
}




@Injectable({
  providedIn: 'root'
})
export class MailgunApiService {

  constructor(
    private http: HttpClient,
    private cricketApi: CricketClubApiService,
    private apiHelper: ServiceApiHelperService,

  ) { }

  public async sendMessage(m: Message) {
    const config = await this.cricketApi.getMailConfiguration();

    const body = new FormData();
    body.append('from', config.fromEmail);
    for(let to of m.toList) {
      body.append('to', to);
    }
    if(m.ccList!=null) {
      for(let cc of m.ccList) {
        body.append('cc', cc);
      }
    } 
    if(m.bccList!=null) {
      for(let bcc of m.bccList) {
        body.append('bcc', bcc);
      } 
    }
    body.append('subject', m.subject);
    body.append('html', m.htmlBody);

    for(let [key, value] of (body as any).entries()) {
      console.log(key, value)
    }

    return this.http.post(`${config.apiUrl}`, body, {
      headers: {
        Authorization: `Basic ${btoa(`api:${config.apiKey}`)}`
      }
    }).subscribe(
      result => {
        console.log('result', result)
      },
      error => {
        console.log('error: ', error);
      }
    );
  }

  /*
  public async sendMessage_(m: Message): Promise<MailgunApiResponse> {
    const config = await this.cricketApi.getMailConfiguration();

    let headers = new HttpHeaders();
    const authHeader = 'Basic ' + window.btoa('api:' + config.apiKey);
    //console.log('authHeader', authHeader);
    headers = headers.set('Authorization', authHeader);

    let url = config.apiUrl.replace('{domain}', config.domain);

    //const fromEmail = m.fromEmail != null ? m.fromEmail : config.fromEmail;
    const fromEmail = config.fromEmail;

    url += '?from=' + encodeURI(fromEmail);

    for(let to of m.toList) {
      url += '&to=' + encodeURI(to);
    }
    if(m.ccList!=null) {
      for(let cc of m.ccList) {
        url += '&cc=' + encodeURI(cc);
      }
    }
    if(m.bccList!=null) {
      for(let bcc of m.bccList) {
        url += '&bcc=' + encodeURI(bcc);
      }
    }

    url += '&subject=' + encodeURI(m.subject);
    url += '&html=' + encodeURI(m.htmlBody);

    console.log('config', config);

    const proxyApi = new HttpProxyApiHelper(config.proxyApiUrl, this.apiHelper, {
      scheme: 'Basic',
      parameter: window.btoa('api:' + config.apiKey)
    });

    const result = await proxyApi.postObjectToUrl(url, '', 'application/json') as MailgunApiResponse;
    console.log('result', result, 'url', url, 'auth', authHeader);
    return Promise.resolve(result);

    //header: Authorization Basic api:<apiKey> and encode it
//https://api.mailgun.net/v3/mail.purplepeace.app/messages?from=shariq@shariqkhan.com&to=shariq.khan@dell.com&subject=Test Postman message&html=data
  }
  */
 
}
