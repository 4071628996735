import * as internal from "assert";

export enum UserRightsEnum {
  canSignUpForFixture = 'Can Signup for Fixture',
  canEditFixture = 'Can Edit Fixture',
  canEditUser = 'Can Edit Users',
  canModifyTeam = 'Can Modify Team',
  canEditScore = 'Can Edit Score',
  canEditVenue = 'Can Edit Venue',
  canSendMessage = 'Can Send Message',
  isPaidMember = 'Is Paid Member',
  isHighPriorityGuest = 'High Priority Guest',
  isClubStaff = 'Club Staff',
}

export interface UserInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone?: string;
  avatarUrl?: string;

  isSuperUser?: boolean;
}

export interface User extends UserInfo {
  rights?: {};
}

//First Name,Last Name,Mobile,Home,Work,E-mail

export interface MCUser {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  home: string;
  work: string;
}

export interface Fixture {
  id: number;
  date: Date;
  opponent: string;
  type: string;
  notes: string;
  venueId: number;

  signedUserIdList: number[];
  isTeamFinal: boolean;
  userCanSignUp: boolean;

  forPaidMembersOnly: boolean;
  ignoreSignUpDuration: boolean;

  teamId: number;
}

export interface Team {
  id: number;

  selectedUserIdList: number[];
  skipperUserId: number;
  keeperUserId: number;

  score: Scorecard;
}

export interface Scorecard {
  notes: string;
}

export interface Venue {
  id: number;
  title: string;
  address: string;
  venueUrl?: string;
  notes: string;
}

export interface EmailTemplate {
  finalTeam: string;

}


export interface WeeklyDigestOptions {
  administrators: boolean;
  paid_members: boolean;
  playing_members: boolean;
  everyone: boolean;
  club_staff: boolean;
  high_priority_guests : boolean;
  include_sign_up_list: boolean;
}