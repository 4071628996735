import { AppConfig, AppContext, AppVariables } from './app.config.model';
import { AzureStorageBroker, AzureStorageBrokerContext, AzureBlobItemMetadata } from '../service-api-helper/azure-storage-broker.worker';
//import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { BlobPlatformApiService } from '../platform-api/blob-platform-api.service';


export enum StorageBrokerObjectEnum {
  user = 'user',
  fixture = 'fixture',
  team = 'team',
  venue = 'venue',
}

export class StorageHelper {
  //readonly apiHelper: ServiceApiHelperService;
  readonly blobApi: BlobPlatformApiService;
  autoStoreMetadata = false;

  constructor(blobApi: BlobPlatformApiService, autoStoreMetadata?: boolean) {
    //this.apiHelper = apiHelper;
    this.blobApi = blobApi;
    this.autoStoreMetadata = autoStoreMetadata!=null ? autoStoreMetadata : false;
  }

  async getStorageBroker(): Promise<AzureStorageBroker> {
    //const config = await this.getAppConfiguration();
    const context: AzureStorageBrokerContext = {
      //accountName: config.storage.accountName,
      //container: config.storage.dataContainer,
      //apiHelper: this.apiHelper,
      blobApi: this.blobApi
    };
    const storage = AzureStorageBroker.create(context);
    //console.log('storage', storage);

    return Promise.resolve(storage);
  }

  
  private async getInfoObjects(type: StorageBrokerObjectEnum): Promise<object[]>
  {
    const typeName = type.toString()
    const storage = await this.getStorageBroker();
    const objects = await storage.getObjects(typeName);
    //console.log('objects', objects);

    const list = [];
    for(let obj of objects) {
      if(obj.metadata!=null) {
        const item = {};
        for(let m of obj.metadata) {
          item[m.key] = m.value;
        }
        list.push(item);
      }
    }

    return Promise.resolve(list);
  }

  public async getObjects(type: StorageBrokerObjectEnum): Promise<object[]>
  {
    const typeName = type.toString()
    const storage = await this.getStorageBroker();
    const objects = await storage.getObjects(typeName);

    const list = [];
    for(let obj of objects) {
      list.push(await storage.getObject(typeName, obj.id));
    }

    return Promise.resolve(list);
  }

  public async getObject(type: StorageBrokerObjectEnum, id: number): Promise<object>
  {
    const typeName = type.toString()
    const storage = await this.getStorageBroker();
    const result = await storage.getObject(typeName, id);
    return Promise.resolve(result);
  }

  getNewObjectId(): number {
    return new Date().valueOf();
  }

  public async storeObject(type: StorageBrokerObjectEnum, id: number, data: Object): Promise<object>
  {
    if(id==null || id==0) {
      id = this.getNewObjectId();
      data['id'] = id;
    }
    const metadata =  this.autoStoreMetadata? this.createMetadata(data) : null;
    //console.log('metadata', metadata);

    const typeName = type.toString();
    const storage = await this.getStorageBroker();

    const result = await storage.storeObject(typeName, id, data, metadata);
    return Promise.resolve(result);
  }

  public async deleteObject(type: StorageBrokerObjectEnum, id: number): Promise<any>
  {
    const typeName = type.toString()
    const storage = await this.getStorageBroker();
    const result = await storage.deleteObject(typeName, id);
    return Promise.resolve(result);
  }

  //async getAppConfiguration(): Promise<AppConfig> {
  //  if(AppContext.appConfig==null) {
  //    const url = AppVariables.configurationUrl;
  //    AppContext.appConfig = await this.apiHelper.getObjectFromUrl(url) as AppConfig;
  //  }

  //  return Promise.resolve(AppContext.appConfig);
  //}

  public async getObjectByName(name: string): Promise<object> {
    const storage = await this.getStorageBroker();
    const result = await storage.getObjectByName(name);
    return Promise.resolve(result);
  }


  createMetadata(item: object, fields?: string[]): AzureBlobItemMetadata[] {
    const list: AzureBlobItemMetadata[] = [];
    if(fields==null) {
      fields = Object.keys(item);
    }
    for(let f of fields) {
      const v = item[f];
      if(typeof v !== 'object' && v !== null) {
        list.push({key: f, value: v});
      }
    }

    return list;
  }


}
