import { User } from './cricket-club-api.model';

export class AppContext {
  public static appConfig: AppConfig = null;
  public static user: User;
}

export class AppVariables {
  public static app_version = 2000004;
  public static clubFullName = 'Merion Cricket Club';
  public static clubShortName = 'Merion';
  //public static clubContactEMail = 'shariq@shariqkhan.com';
  //public static clubContactName = 'Shariq Khan';
  public static clubUrl = 'http://cricketatmerion.com';   // this sets up redirect; don't use https://

  public static readonly key_loggedInUser = 'key.loggedInUser';

  public static configurationUrl = '/assets/data/app.config.json';
  public static email_Final_Team_TemplateName = '/assets/data/email.final-team.template.html';
  public static email_Weekly_Digest_TemplateName = '/assets/data/email.weekly-digest.template.html';
  public static mailgunConfigurationName = '/assets/data/app.config.mailgun.json';
}

export interface AppConfig {
  app_version: number;
  ui: UiConfiguration;
  storage: AzureStorageConfiguration;
  mail: MailgunConfiguration;
}

export interface AzureStorageConfiguration {
  accountName: string;

  dataContainer: AzureStorageContainerInfo;
}

export interface AzureStorageContainerInfo {
  name: string;
  sas: string;
}

export interface MailgunConfiguration {
  apiKey: string;
  apiUrl: string;
  fromEmail: string;
  //domain: string;
  //proxyApiUrl: string;
}


export interface UiConfiguration {
  appName: string;
}
