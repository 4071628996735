
export class XmlDomHelper {
  public createXmlDocument(xml: string): Document {
    let parser = new DOMParser();
    let doc = parser.parseFromString(xml, 'text/xml');
    return doc;
  }

  public getElements(doc: Document, tagName: string): HTMLCollectionOf<Element> {
    return doc.getElementsByTagName(tagName);
  }

  public findDomNode(node: Element, path: string) : Element {
    let tags = path.split('/');
    //let n: Element = node;

    for(let tag of tags) {
      let list = node.getElementsByTagName(tag);
      //console.log('looking for '+tag+', found: ' + list.length);
      if(list.length>0) {
        node = list[0];
      }
      else {
        node = null;
        break;
      }
    }

    return node;
  }

  public findDomNodeText(node: Element, path: string) : string {
    let result = this.findDomNode(node, path);

    if(result==null) {
      return null;
    }
    else {
      return result.textContent;
    }

  }

}
