import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformConfigurationApiService {

  public readonly appId: string;
  public readonly platformUri: string;
  public readonly storageId: string;

  public readonly key_platform_storageId = 'blobStorageId';
  public readonly key_platform_appId = 'appId';

  constructor() {
    this.appId = "1621569600000";

    //this.platformUri = 'https://api.dig-ix.com/api';
    //this.platformUri = 'https://localhost:44319/api';
    //this.platformUri = 'https://localhost:5001/api';
    this.platformUri = 'https://com-shariqkhan-platform-api-service.azurewebsites.net/api'

    this.storageId = '1621569600010';
  }

}
