
export class AppHelper {

  public static getDateWithoutTime(src: Date): Date {
    return new Date(src.getFullYear(), src.getMonth(), src.getDate());
  }

  public static replaceAll(str: string, key: string, value: string) {
    if(str==null || str.length==0) {
      return str;
    }
    else {
      return str.replace(new RegExp(key, 'g'), value);
    }
  }


  public static formatDateToString(date : Date) : string
  {
   const options : any = {
       weekday: "long",
       month: "long",
       day: "numeric",
       hour: "2-digit",
       minute: "2-digit",
       timeZone: 'America/New_York'  // doesn't work on safari
   };

   var d = new Date(date);  // convert to a javascript date type
   //var offsetHours = d.getTimezoneOffset()/60; // calculate the difference in hours
   //var td = new Date(d.getFullYear(), d.getMonth(), d.getDay(), d.getHours()+offsetHours, d.getMinutes()); // apply +4 time zone
   return d.toLocaleString('en-us', options);
  }


  public static removeItemFromArray(list: any[], item: any): boolean {
    if(list==null || item==null) { return false; }

    const pos = list.indexOf(item);
    if(pos>=0) {
      list.splice(pos, 1);
      return true;
    }
    else {
      return false;
    }

  }

  static idSeed: number;
  static idSeedCounter: number;

  public static getNewId(): number {
    if(AppHelper.idSeed==null) {
      AppHelper.idSeed = new Date().valueOf();
      AppHelper.idSeedCounter = 0;
    }

    AppHelper.idSeedCounter++;
    return AppHelper.idSeed + AppHelper.idSeedCounter;
  }

}
