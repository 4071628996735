import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { Debug } from './service-api-debug-log';

@Injectable({
  providedIn: 'root'
})
export class ServiceApiHelperService {

  constructor(
    public http: HttpClient,
    private storage: Storage
  )
 { }

 public removeItemFromStore(key: string): Promise<any> {
   return new Promise((resolve, reject) => {
     this.storage.remove(key).then(() => {
       resolve(true);
     }, error => {
       reject(error);
     });
   });
}

public saveObjectToStore(key: string, obj: object): Promise<object> {
    return new Promise((resolve, reject) => {
      var data = (obj==null) ? '' : JSON.stringify(obj);
      this.storage.set(key, data)
        .then((result) => {
          resolve(result);
        }, error => { console.log('saveObjectToStore', error); reject(error); });
    });
  }

public loadObjectFromStore(key: string): Promise<object> {
  //console.log('loadObjectFromStore("' + key + '")', key);
  return new Promise((resolve, reject) => {
    this.storage.get(key)
      .then((json) => {
        if(json == null || json=='') {
          resolve(null);
        }
        else {
          let result = JSON.parse(json);
          //console.log('result', result);
          resolve(result);
        }
      }, error => { console.log('loadObjectFromStore', error); reject(error); });
    });
  }

public getXmlResponseFromUrl(url: string, request_headers?: HttpHeaders) : Promise<string> {
  let ctx = this;

  return new Promise(function(resolve, reject) {
    //console.log('getting xml response from', url);
    let headers = new HttpHeaders();
    if(request_headers!=null) {
      for(let key of request_headers.keys()) {
        headers = headers.set(key, request_headers.get(key));
      }
    }
    ctx.http.get(url, { headers: headers, responseType: 'text'})
    .subscribe(
      result => {
        resolve(result);
      },
      error => {
        console.log('error: ', error);
        reject(error)
      }
    );
  });

}

public getObjectFromUrlWithHeaders(url: string, headers: HttpHeaders) : Promise<object> {
    const ctx = this;

    return new Promise(function(resolve, reject) {
      //console.log('getting', url);
      ctx.http.get(url, {headers: headers})
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          console.log('error: ', error);
          reject(error)
        }
      );
    });

  }

  public deleteObjectAtUrl(url: string, isResponseTypeText?: boolean): Promise<any> {
    const ctx = this;

    let options;
    if(isResponseTypeText==true) {
      options = { headers: new HttpHeaders(), responseType: 'text'};
    }
    else {
      options = { headers: new HttpHeaders() };
    }

    return new Promise(function(resolve, reject) {
      ctx.http.delete(url, options)
        .subscribe(
          result => {
            resolve(result);
          },
          error => {
            //console.log('error', error);
            reject(error)
          }
        );
      });
  }

  public deleteObjectAtUrlWithHeaders(url: string, headers: HttpHeaders): Promise<any>
  {
    const ctx = this;

    return new Promise(function(resolve, reject) {
      ctx.http.delete(url, {headers: headers})
        .subscribe(
          result => {
            resolve(result);
          },
          error => {
            //console.log('error', error);
            reject(error)
          }
        );
      });
  }


  public getObjectFromUrl(url: string) : Promise<object> {
    let ctx = this;

    return new Promise(function(resolve, reject) {
      //console.log('getting', url);
      ctx.http.get(url)
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          console.log('error: ', error, '=> for url: ', url);
          reject(error)
        }
      );
    });

  }

  public postObjectToUrlWithHeaders(url: string, data: object, headers: HttpHeaders, isResponseTypeText: boolean) : Promise<any> {
    let ctx = this;

    return new Promise(function(resolve, reject) {
      //console.log('posting to', url, 'data', data);
      const body = data!=null ? JSON.stringify(data) : '';

      const options = ctx.createHttpOptions(headers, isResponseTypeText);

      ctx.http.post(url, body, options)
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          console.log('error: ', error);
          reject(error)
        }
      );
    });

  }


  public postObjectToUrl(url: string, data: object) : Promise<any> {
    let ctx = this;

    return new Promise(function(resolve, reject) {
      //console.log('posting to', url, 'data', data);
      const body = data!=null ? JSON.stringify(data) : '';


      ctx.http.post(url, body)
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          console.log('error: ', error);
          reject(error)
        }
      );
    });

  }

  stringifyObject(data: object): string {
    //console.log('in stringifyObject');
    if(data==null) {
      return '';
    }
    else {
      if (typeof data === 'string') {
        //console.log('data is string');
        return data as string;
      }
      else if(data instanceof String) {
        //console.log('data is string object');
        return data.toString();
      }
      else {
        //console.log('data is object');
        return JSON.stringify(data);
      }
    }
  }

  private createHttpOptions(headers?: HttpHeaders, isResponseTypeText?: boolean): any {
    let options: any = null;
    if(headers!=null && isResponseTypeText!=null) {
      options = { headers: headers, responseType: 'text'};
    }
    else if(headers!=null && isResponseTypeText==null) {
      options = { headers: headers }
    }
    else if(headers==null && isResponseTypeText!=null) {
      options = { responseType: 'text '}
    }

    return options;
  }

  public putObjectAtUrl(url: string, data: object, headers?: HttpHeaders, isResponseTypeText?: boolean) : Promise<any> {
    let ctx = this;

    return new Promise(function(resolve, reject) {
      Debug.logO('putting at: ' + url, data);
      const body = ctx.stringifyObject(data); // data!=null ? JSON.stringify(data) : '';
      const options = ctx.createHttpOptions(headers, isResponseTypeText);

      ctx.http.put(url, body, options)
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          Debug.logO('error: ', error);
          reject(error)
        }
      );
    });

  }

  public downloadFileFromUrl(url: string, headers?: HttpHeaders) : Promise<Blob> {
    let ctx = this;

    return new Promise(function(resolve, reject) {
      ctx.http.get(url, { headers: headers, responseType: 'blob'})
      .subscribe(
        result => {
          resolve(result);
        },
        error => {
          Debug.logO('error: ', error);
          reject(error)
        }
      );
    });
  }



  public b64toBlob(b64Data:string, contentType:string, sliceSize? :number) : Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


}
