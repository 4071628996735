import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { User, UserRightsEnum } from '../cricket-club-api/cricket-club-api.model'
import { AppContext, AppVariables } from '../cricket-club-api/app.config.model';

import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { CricketClubApiService } from '../cricket-club-api/cricket-club-api.service';

export interface SavedUser {
  id: number;
  loggedIn: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public isLoginSubject = new BehaviorSubject<User>(this.getCurrentUser());

  constructor(
    private cricketApi: CricketClubApiService,
    private apiHelper: ServiceApiHelperService,
  ) {

  }

  async rememberUser(u: User) {
    if(u==null) {
      await this.apiHelper.saveObjectToStore(AppVariables.key_loggedInUser, null);
    }
    else {
      const obj: SavedUser = {
        id: u.id,
        loggedIn: new Date()
      };
      await this.apiHelper.saveObjectToStore(AppVariables.key_loggedInUser, obj);
    }

  }


  public async initialize() {
    const savedUser = await this.apiHelper.loadObjectFromStore(AppVariables.key_loggedInUser) as SavedUser;
    if(savedUser!=null) {
      let user = null;
      try {
        user = await this.cricketApi.getUser(savedUser.id);
      }
      catch(err) {
        console.log('Error in fetching user', err);
      }

      //const user = await this.cricketApi.getUser(savedUser.id);
      if(user!=null) {
        this.rememberUser(user);    // no need to wait
        AppContext.user = user;
      }
    }

    this.isLoginSubject.next(AppContext.user);

  }

  public isAuthenticated(): boolean {
    return AppContext.user != null;
  }

  public getCurrentUser(): User {
    return AppContext.user;
  }

  public user_right_exists(u: User, right: UserRightsEnum): boolean {
    let flag = u.isSuperUser==true;
    if(flag==false) {
      const keys = Object.keys(UserRightsEnum).filter(x => UserRightsEnum[x] == right);
      if(keys.length>0) {
        if(u.isSuperUser==true) { return true; }
        flag = u.rights!=null && u.rights[keys[0]]==true;
      }
      //console.log(u.email + ": " + flag, u);
    }


    return flag;
  }

  public userHasRight(right: UserRightsEnum): boolean {
    let keys = Object.keys(UserRightsEnum).filter(x => UserRightsEnum[x] == right);
    if(keys.length==0) {
      return false;   // right not found
    }
    let key = keys[0];

    //console.log('in here', key);
    if(!this.isAuthenticated()) {
      //console.log('no authenticated user');
      return false;
    }
    const u = this.getCurrentUser();
    //console.log('right', right, 'u', u);

    if(u.isSuperUser==true) { return true; }
    //if(u.rights==null) { return false; }
    //console.log('right', right, 'user', u);
    const result = u.rights!=null && u.rights[key]==true;
    //console.log(UserRightsEnum[right], result, u);
    return result;
  }

  public async loginUser(userName: string, password: string, rememberMe: boolean): Promise<boolean> {
    const users = await this.cricketApi.getUsers();
    //console.log('users', users);

    const list = users.filter(u => u.email.toLowerCase()==userName.toLowerCase() && u.password.toLowerCase()==password.toLowerCase());

    if(list!=null && list.length>0) {
      const u = list[0];
      const user = await this.cricketApi.getUser(u.id);
      //console.log('found', user);
      AppContext.user = user;
      this.isLoginSubject.next(user);

      if(rememberMe) {
        this.rememberUser(u); // no need to wait
      }

      return Promise.resolve(true);
    }
    else {
      return Promise.resolve(false);
    }

  }

  public async logoutUser() {
    await this.rememberUser(null);
    AppContext.user = null;
    this.isLoginSubject.next(null);
  }
}
