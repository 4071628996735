import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { PlatformConfigurationApiService } from './platform-configuration-api.service';
import { AppHelper } from '../cricket-club-api/app-helper.worker';

export interface TableQueryResult {
  value: TableEntity[];
}

export interface TableEntity {
  PartitionKey?: string;
  RowKey?: string;
  Timestamp?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class TablePlatformApiService {

  readonly tableUri: string;

  private readonly field_id = 'id';
  private readonly field_row_key = 'RowKey';
  private readonly field_partition_key = 'PartitionKey';

  constructor(
    private config: PlatformConfigurationApiService,
    private http: ServiceApiHelperService
  ) {

    this.tableUri = this.config.platformUri + '/tables';
  }

  private getRequestHeaders(): HttpHeaders {
    const obj = {};
    obj[this.config.key_platform_appId] = this.config.appId;
    obj[this.config.key_platform_storageId] = this.config.storageId;

    const authHeader = 'Basic ' + window.btoa(JSON.stringify(obj));

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', authHeader);

    return headers;
  }

  public async deleteEntity(tableName: string, rowKey: string): Promise<any> {
    const headers = this.getRequestHeaders();

    const url = this.tableUri + "/delete?tableName=" + tableName + '&partitionKey=' + tableName + '&rowKey=' + rowKey;
    const result = await this.http.deleteObjectAtUrlWithHeaders(url, headers);
    //console.log('result', result);
    return Promise.resolve(result);
  }

  public async insertOrReplaceEntity<T>(tableName: string, item: T): Promise<T> {
    const results = await this.insertOrReplace<T>(tableName, [item]);
    if(results.length>0) {
      return Promise.resolve(results[0]);
    } else {
      return Promise.resolve(null);
    }
  }


  public async insertOrReplace<T>(tableName: string, objects: T[]): Promise<T[]>
  {
    const headers = this.getRequestHeaders();

    try {
      const url = this.tableUri + "/store?tableName=" + tableName;

      const entities = JSON.parse(JSON.stringify(objects));

      for(let entity of entities) {
        if(entity[this.field_id]==null) { entity[this.field_id] = AppHelper.getNewId(); }
        if(entity[this.field_partition_key]==null) { entity[this.field_partition_key] = tableName; }
        if(entity[this.field_row_key]==null) { entity[this.field_row_key] = entity[this.field_id].toString(); }

        const keys = Object.keys(entity);

        for(let k of keys) {
          const v = entity[k];
          if(typeof v == 'object' && v != null && (!(v instanceof Date))) {
            entity[k + this.const_json_type] = 'json';
          }
        }

        this.removeNullValues(entity);
      }

      //console.log('entities', entities);

      await this.http.postObjectToUrlWithHeaders(url, entities, headers, false);
      return Promise.resolve(entities);
    }
    catch(err) {
      return Promise.reject(err);
    }
  }

  public async getEntity<T>(tableName: string, rowKey: string): Promise<T> {
    var query = { RowKey: rowKey };
    var results = await this.query(tableName, query);

    if(results.length>0) {
      return Promise.resolve(results[0] as T);
    }
    else {
      return Promise.resolve(null);
    }
  }

  public async getEntities<T>(tableName: string): Promise<T[]> {
    const results = await this.query(tableName, {});
    return Promise.resolve(results as T[]);

  }

  private readonly const_odata_type = '@odata.type';
  private readonly const_json_type = '___data_type';
  private readonly const_json_type_value = 'json';

  public async query(tableName: string, query: object): Promise<TableEntity[]>
  {
    const headers = this.getRequestHeaders();

    try {
      const url = this.tableUri + "/query2?tableName=" + tableName;
      const json = await this.http.postObjectToUrlWithHeaders(url, query, headers, false) as string;
      //console.log('json', json);
      const query_results = JSON.parse(json) as TableQueryResult[];
      //console.log('query_results', query_results);

      let results: TableEntity[] = [];
      for(let qr of query_results) {
        results = results.concat(qr.value);
      }

      for(var e of results) {
        const keys = Object.keys(e);
        //console.log('keys', keys);

        for(let k of keys) {
          let typeInfo = e[k + this.const_odata_type] as string;
          if(e[k]!=null && typeInfo!=null) {
            if(typeInfo.endsWith('.Int64')) {
              e[k] = parseInt(e[k]);
            }
            else if(typeInfo.endsWith('.DateTime')) {
              e[k] = new Date(Date.parse(e[k].toString()));
            }
          }

          if(e[k]!=null && e[k + this.const_json_type]==this.const_json_type_value) {
            e[k] = JSON.parse(e[k]);
            delete e[k + this.const_json_type];
          }
        }

        e.Timestamp = new Date(Date.parse(e.Timestamp.toString()));

        for(let k of keys) {
          if(k.endsWith(this.const_odata_type)) {
            delete e[k];
          }
        }
        delete e['odata.etag'];

      }

      //console.log(tableName, results);

      return Promise.resolve(results);
    }
    catch(err) {
      return Promise.reject(err);
    }

  }

  removeNullValues(obj: object) {
    //console.log('Removing null values', obj);

    for(let key of Object.keys(obj))
      if (key.startsWith('$') || obj[key] == null) {
        //console.log('removing key', key);
        delete obj[key];
      }
      else if (obj[key] && typeof obj[key] === 'object') {
        this.removeNullValues(obj[key]);
      }

    }
  

}
