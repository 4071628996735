import { Component, OnInit, AfterViewInit } from '@angular/core';

import { CricketClubApiService } from '../../services/cricket-club-api/cricket-club-api.service';
import { Fixture, User, Team } from '../../services/cricket-club-api/cricket-club-api.model';
import { NotificationHelper } from '../../services/cricket-club-api/notifications.helper';
import { MailgunApiService } from '../../services/mailgun-api/mailgun-api.service';
import { UxApiHelperService } from '../../services/service-api-helper/ux-api-helper.service';
import { AuthenticationService } from '../../services/authentication-api/authentication-api.service';

export interface SignedUpUser {
  selected: boolean;
  user: User;
}

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.page.html',
  styleUrls: ['./edit-team.page.scss'],
})
export class EditTeamPage implements OnInit, AfterViewInit {

  editMode = false;
  dialogTitle = 'Edit Team';
  initialized = false;
  modified = false;
  signUps: SignedUpUser[];
  team: Team;
  notifyTeam = false;

  fixture: Fixture;
  currentUser: User;
  userCanModifyTeam: boolean;

  constructor(
    private cricketApi: CricketClubApiService,
    private mailApi: MailgunApiService,
    private auth_api: AuthenticationService,
    private ux: UxApiHelperService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initialize();
  }

  async initialize() {
    const loading = await this.ux.showLoading();

    try {
      this.team = {} as Team;

      if(this.fixture.teamId!=null) {
        try {
          this.team = await this.cricketApi.getTeam(this.fixture.teamId);
        }
        catch(err) {
          console.log('error in fetching team', err);
        }
      }
      if(this.team.selectedUserIdList==null) { this.team.selectedUserIdList=[]; }
      if(this.fixture.signedUserIdList==null) { this.fixture.signedUserIdList=[]; }

      const signUps: SignedUpUser[] = [];
      for(let i=this.fixture.signedUserIdList.length-1; i>=0; i--) {
        const id = this.fixture.signedUserIdList[i];
        let user = null;
        if(id!=null) {
          try {
            user = await this.cricketApi.getUser(id);
          }
          catch(err) {
            console.log('error in fetching user', err);
            this.fixture.signedUserIdList.splice(i, 1);
          }
        }

        if(user!=null) {
          const selected = this.team.selectedUserIdList.indexOf(id) >= 0;

          signUps.push({selected: selected, user: user});
        }
      }

      this.signUps = signUps.sort((a,b)=> {
        return (a.user.firstName+a.user.lastName).toLowerCase()>(b.user.firstName+b.user.lastName).toLowerCase() ? 1 : -1
      });

      this.dialogTitle = 'Team vs. ' + this.fixture.opponent;
      //console.log('state', this);

      //console.log('initialized!');
      this.editMode = this.userCanModifyTeam;

      this.initialized = true;
    }
    finally {
      loading.dismiss();
    }
  }

  async discardChanges() {
    if(this.modified) {
      await this.ux.confirm('Cancel changes', 'You have made changes that are not saved yet.  Click OK to discard changes?', async () => {
        this.ux.dismissModalDialog(null);
      });
    }
    else {
      this.ux.dismissModalDialog(null);
    }

  }

  async commitChanges() {
    const loading = await this.ux.showLoading();

    try {
      this.team.selectedUserIdList = [];
      for(let u of this.signUps) {
        if(u.selected) {
          this.team.selectedUserIdList.push(u.user.id);
        }
      }
      const team = await this.cricketApi.storeTeam(this.team);
      //console.log('team', team);

      this.fixture.teamId = team.id;
      const fixture = await this.cricketApi.storeFixture(this.fixture);
      //console.log('fixture', fixture);

      if(this.notifyTeam && this.fixture.isTeamFinal) {
        const obj = new NotificationHelper(this.cricketApi, this.mailApi, this.auth_api);
        await obj.sendFinalTeamEmail(this.fixture, this.team, this.currentUser);

        await this.ux.presentAlert('Email notifications were successfully sent.');
      }

      this.ux.dismissModalDialog(fixture);
    }
    catch(err) {
      await this.ux.presentAlert('An unexpected error occured in the saving Team. Please try again in a few minutes or contact support.')
      throw err;
    }
    finally {
      loading.dismiss();
    }
  }

  changed() {
    this.modified = true;
  }

  getSelectedUsers(): SignedUpUser[] {
    return this.signUps.filter( u => u.selected==true );
  }

}
