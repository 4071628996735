import { Component, OnInit, } from '@angular/core';

import { EditObjectPropertiesConfig, EditObjectPropertiesArray } from './edit-object-properties.model';
import { UxApiHelperService } from '../../services/service-api-helper/ux-api-helper.service';

@Component({
  selector: 'app-edit-object-properties',
  templateUrl: './edit-object-properties.page.html',
  styleUrls: ['./edit-object-properties.page.scss'],
})
export class EditObjectPropertiesPage implements OnInit {

  modified = false;
  config: EditObjectPropertiesConfig;
  data: object;
  initialized = false;
  commitIcon = 'checkmark-circle-outline';
  ok_button_text = 'Save';

  constructor(
    private ux: UxApiHelperService
  ) { }

  ngOnInit() {
    //console.log('config', this.config);

    for(let g of this.config.fieldGroups) {
      if(g.fields!=null) {
        for(let f of g.fields) {
          if(f.type==null) { f.type = 'text' };
          if(f.required==null) { f.required = false; }
          if(f.placeholder==null) { f.placeholder = '' }
          if(f.multiple==null) { f.multiple = false; }
        }
      }

      if(g.arrays!=null) {
        for(let a of g.arrays) {
          if(this.data[a.fieldName]==null) {
            this.data[a.fieldName]=[];
          }
        }
      }
    }

    if(this.config.ok_button_text!=null) {
      this.ok_button_text = this.config.ok_button_text;
    }

    this.modified = false;
    this.initialized = true;
  }

  async editObjectArray(a: EditObjectPropertiesArray, item: object) {
    let obj = {};
    if(item!=null) {
      obj = JSON.parse(JSON.stringify(item))
    }

    const data = await this.ux.showModalDialog(EditObjectPropertiesPage, {
      config: a.config,
      data: obj
    });

    if(data == null) {
      return;
    }

    //console.log('array', a);
    //console.log('data', data);
    const items = this.data[a.fieldName];
    if(item==null) {
      items.push(data);
    }
    else {
      const pos = items.indexOf(item);
      if(pos>=0) {
        items.splice(pos, 1, data);
      }
    }

    //console.log('object', this.data);
    this.modified = true;
  }

  async discardChanges() {
    if(this.modified) {
      await this.ux.confirm('Cancel changes', 'You have made changes that are not saved yet.  Click OK to discard changes?', async () => {
        this.ux.dismissModalDialog(null);
      });
    }
    else {
      this.ux.dismissModalDialog(null);
    }
  }

  changed() {
    this.modified = true;
  }

  commitChanges() {
    this.ux.dismissModalDialog(this.data);
  }
}
