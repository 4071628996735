import { Injectable } from '@angular/core';
import { ModalController, LoadingController, ToastController, AlertController, ActionSheetController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UxApiHelperService {

  constructor(
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private actionCtrl: ActionSheetController,
  ) { }

  public async showLoading(message?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if(message==null) {
        message = "Please wait...";
      }

      const loading = await this.loadingCtrl.create({
        message: message,
        spinner: 'crescent',
      });
      await loading.present();

      return resolve(loading);

    });
  }

  public async showModalDialog(component: any, componentProps: any): Promise<any> {
    let dialog = await this.modalCtrl.create({
      component: component,
      componentProps: componentProps,
      cssClass: 'my-custom-modal-css',
      backdropDismiss: false,
    });

    await dialog.present();

    const { data  } = await dialog.onWillDismiss();
    return Promise.resolve(data);
  }

  public dismissModalDialog(result?: object) {
    this.modalCtrl.dismiss(result);
  }

  public showConfirmationDialog(message: string, handler: any) {
    if(confirm(message)) {
      handler();
    }
  }

  public getConfirmation(message: string): boolean {
    return confirm(message);
  }

  public async confirm(header: string, message: string, okHandler?: any, cancelHandler?: any, subHeader?: string) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      cssClass: 'confirmation-dialog',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if(okHandler!=null) {
              okHandler();
            }
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            if(cancelHandler!=null) {
              cancelHandler();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  public async showToast(message: string, durationInSeconds?: number): Promise<any> {
    if(durationInSeconds==null) { durationInSeconds = 5; }

    const toast = await this.toastCtrl.create({
      message: message,
      duration: durationInSeconds*1000,
      position: 'bottom',
    });
    toast.present();
    return toast;
  }

  public async presentActionSheet(header: string, buttons: any[]) {
    const actionSheet = await this.actionCtrl.create({
      header: header,
      buttons: buttons
    });
    await actionSheet.present();

  }


  public async presentAlert(message: string, header?: string, subHeader?: string, buttonText?: string) {
    if(buttonText==null) { buttonText = 'OK' }

    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      cssClass: 'alert-dialog',
      buttons: [{
        text: buttonText,
        role: 'cancel'
      }]
    });

    await alert.present();
  }


}
