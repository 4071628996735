import { Component, OnInit } from '@angular/core';

import { CricketClubApiService } from '../../services/cricket-club-api/cricket-club-api.service';
import { Fixture, User, UserRightsEnum } from '../../services/cricket-club-api/cricket-club-api.model';
import { AppHelper } from '../../services/cricket-club-api/app-helper.worker';
import { UxApiHelperService } from '../../services/service-api-helper/ux-api-helper.service';

import { EditObjectPropertiesConfigField } from '../edit-object-properties/edit-object-properties.model';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.page.html',
  styleUrls: ['./edit-user.page.scss'],
})
export class EditUserPage implements OnInit {

  dialogTitle = 'Edit User';
  isNewUser = false;
  modified = false;
  initialized = false;
  fixtures: Fixture[];
  user: User;
  readonly fields: EditObjectPropertiesConfigField[] = [
    { label: 'First Name', fieldName: 'firstName', type: 'text', required: true },
    { label: 'Last Name', fieldName: 'lastName', type: 'text', required: true },
    { label: 'E-mail', fieldName: 'email', type: 'text', required: true },
    { label: 'Password', fieldName: 'password', type: 'text', required: true },
    { label: 'Phone', fieldName: 'phone', type: 'text', placeholder: 'Provide a contact phone number' },
    { label: 'Image Url', fieldName: 'avatarUrl', type: 'text' },
  ];
  rights: string[] = []; // {key: UserRightsEnum, title: string}[];
  rightNames: string[] = [];

  constructor(
    private cricketApi: CricketClubApiService,
    private ux: UxApiHelperService,
  ) { }


  ngOnInit() {
    this.initialize();
  }

  async discardChanges() {
    if(this.modified) {
      await this.ux.confirm('Cancel changes', 'You have made changes that are not saved yet.  Click OK to discard changes?', async () => {
        this.ux.dismissModalDialog(null);
      });
    }
    else {
      this.ux.dismissModalDialog(null);
    }

  }

  /*
  discardChanges() {

    if(this.modified && !confirm('You have made changes that are not saved yet. Are you sure you want to discard changes?')) {
      return;
    }

    this.modalCtrl.dismiss(null);
  }
  */

  async commitChanges() {
    let rights = {};
    if(!this.user.isSuperUser) {
      for(let r of Object.keys(this.user.rights)) {
        if(this.user.rights[r]==true) {
          rights[r] = true;
        }
      }
    }
    this.user.rights = rights;

    this.ux.dismissModalDialog(this.user);
  }

  async initialize() {
    const loading = await this.ux.showLoading();

    try {
      this.isNewUser = this.user.id==null || this.user.id==0;

      if(this.user.rights==null) { this.user.rights = {}};

      this.rightNames = Object.values(UserRightsEnum);
      this.rights = Object.keys(UserRightsEnum);

      if(!this.isNewUser) {
        this.fixtures = await this.cricketApi.getFixtures();
      }

      this.initialized = true;
    }
    finally {
      loading.dismiss();
    }
  }

  isUserSignedUp(f: Fixture) {
    if(this.isNewUser) { return false; }
    return this.cricketApi.isUserSignedUp(f, this.user.id);
  }

  async signUpForFixture(f: Fixture) {
    const loading = await this.ux.showLoading();

    try {
      const result = await this.cricketApi.signUpForFixture(f, this.user.id);
      const pos = this.fixtures.indexOf(f);
      if(pos>=0) {
        this.fixtures.splice(pos, 1, result);
      }
    }
    finally {
      loading.dismiss();
    }
  }

  formatDateToString(d: Date): string {
    return AppHelper.formatDateToString(d);
  }


  async changed() {
    this.modified = true;
  }

}
