import { Injectable } from '@angular/core';

import { ServiceApiHelperService } from '../service-api-helper/service-api-helper.service';
import { AppConfig, AppContext, AppVariables, MailgunConfiguration } from './app.config.model';
import { AppHelper } from './app-helper.worker';
//import { AzureStorageBroker } from '../service-api-helper/azure-storage-broker.worker';
import { StorageHelper, StorageBrokerObjectEnum } from './cricket-club-storage.helper';
import { User, Fixture, Team, Venue, EmailTemplate } from './cricket-club-api.model';
import { BlobPlatformApiService } from '../platform-api/blob-platform-api.service';
import { TablePlatformApiService } from '../platform-api/table-platform-api.service';
//import { ObjectType, CricketClubSqlDataApiService } from './cricket-club-sql-data-api.service'




@Injectable({
  providedIn: 'root'
})
export class CricketClubApiService {
  config: AppConfig;

  constructor(
//    private cc_sql_api: CricketClubSqlDataApiService, 
    private table_api: TablePlatformApiService,
    private apiHelper: ServiceApiHelperService,
    private blob_api: BlobPlatformApiService
    )
  {
  }

  public readonly tables = {
    fixture: 'Fixture',
    venue: 'Venue',
    app_user: 'AppUser',
    //user_group: 'UserGroup',
    //channel: 'Channel',
    team: 'Team',
  }
    
  public isUserSignedUp(f: Fixture, userId: number): boolean {
    if(f.signedUserIdList==null) { return false; }

    const pos = f.signedUserIdList.indexOf(userId);
    return pos>=0;
  }

  public async signUpForFixture(f: Fixture, userId: number): Promise<Fixture> {
    if(f.signedUserIdList==null) { f.signedUserIdList = [] };

    const pos = f.signedUserIdList.indexOf(userId);
    if(pos>=0) {
      f.signedUserIdList.splice(pos, 1);
    }
    else {
      f.signedUserIdList.push(userId);
    }
    const result = await this.storeFixture(f);

    return Promise.resolve(result);
  }

  public async getObjectFromUrlAsString(url: string): Promise<string> {
    return await this.apiHelper.getXmlResponseFromUrl(url) as string;
    /*
    const storage = new StorageHelper(this.apiHelper);
    const result = await storage.getObjectByName(AppVariables.emailTemplateConfigName) as EmailTemplate;
    console.log('result', result);
    return Promise.resolve(result);
    */
  }

  public async getMailConfiguration(): Promise<MailgunConfiguration> {
    /*
    const storage = new StorageHelper(this.blob_api);
    const config = await storage.getObjectByName(AppVariables.mailgunConfigurationName) as MailgunConfiguration;
    return Promise.resolve(config);
    */

    const json = await this.getObjectFromUrlAsString(AppVariables.mailgunConfigurationName);
    const config = JSON.parse(json) as MailgunConfiguration;
    return Promise.resolve(config);

  }


  fixFixtureProperties(f: Fixture) {
    if(f.signedUserIdList==null) { f.signedUserIdList = []; }

    // fix the date issue
    if(f.date==null) {
      f.date = new Date();
    }
    else {
      f.date = new Date(f.date);
    }
  }

  public async store_entity(table_name: string, obj: Object) {
    await this.table_api.insertOrReplaceEntity<object>(table_name, obj);

  }

  public get_all_table_names(): string[] {
    const list: string[] = [];
    for(let key of Object.keys(this.tables)) {
      list.push(this.tables[key]);
    }
    return list;
  }

  public async export_all_data_from_blob_to_table() {

    const mappings: { target_table: string, src_type: StorageBrokerObjectEnum }[] = [
      { target_table: this.tables.app_user, src_type: StorageBrokerObjectEnum.user },
      { target_table: this.tables.team, src_type: StorageBrokerObjectEnum.team },
      { target_table: this.tables.fixture, src_type: StorageBrokerObjectEnum.fixture },
      { target_table: this.tables.venue, src_type: StorageBrokerObjectEnum.venue },
    ]

    const storage = new StorageHelper(this.blob_api);
    for(let map of mappings) {
      console.log('*** fetching', map);
      const objects = await storage.getObjects(map.src_type);
      console.log('exporting', objects);
      const results = this.table_api.insertOrReplace(map.target_table, objects);
      console.log('finished');
    }

  }

  public async getFixtures(): Promise<Fixture[]> {
    //let results = await this.cc_sql_api.getAllObjects(ObjectType.fixture) as Fixture[];
    //const storage = new StorageHelper(this.blobApi);
    //let results = await storage.getObjects(StorageBrokerObjectEnum.fixture) as Fixture[];
    //console.log('fixtures', results);

    let results = await this.table_api.getEntities<Fixture>(this.tables.fixture);

    for(let r of results) {
      this.fixFixtureProperties(r);
    }

    // remove old fixtures
    const today = AppHelper.getDateWithoutTime(new Date());
    for(let i=results.length-1; i>=0; i--) {
      const d = AppHelper.getDateWithoutTime(results[i].date);
      if(d < today) {
        results.splice(i, 1);
      }
    }

    results = results.sort((a,b) => {
      //return Date.parse(a.toString()).valueOf()>Date.parse(b.toString()).valueOf() ? 1 : -1;
      return a.date>b.date ? 1 : -1;
    });

    //console.log('fixtures', results);
    return Promise.resolve(results);
  }

  public async getFixture(id: number): Promise<Fixture> {
    //const storage = new StorageHelper(this.blobApi);
    //let result = await storage.getObject(StorageBrokerObjectEnum.fixture, id) as Fixture;

    const result = await this.table_api.getEntity<Fixture>(this.tables.fixture, id.toString());
    return Promise.resolve(result);
  }

  public async storeFixture(obj: Fixture): Promise<Fixture> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.storeObject(StorageBrokerObjectEnum.fixture, obj.id, obj) as Fixture;

    const result = await this.table_api.insertOrReplaceEntity<Fixture>(this.tables.fixture, obj);
    this.fixFixtureProperties(result);
    return Promise.resolve(result);
  }

  public async deleteFixture(id: number): Promise<any> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.deleteObject(StorageBrokerObjectEnum.fixture, id) as any;

    const result = await this.table_api.deleteEntity(this.tables.fixture, id.toString());
    return Promise.resolve(result);
  }

  public async getUsers(): Promise<User[]> {
    //const storage = new StorageHelper(this.blobApi);
    //const results = await storage.getObjects(StorageBrokerObjectEnum.user) as User[];

    const results = await this.table_api.getEntities<User>(this.tables.app_user);
    return Promise.resolve(results);
  }

  public async getUser(id: number): Promise<User> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.getObject(StorageBrokerObjectEnum.user, id) as User;

    const result = await this.table_api.getEntity<User>(this.tables.app_user, id.toString());
    return Promise.resolve(result);
  }

  public async storeUser(obj: User): Promise<User> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.storeObject(StorageBrokerObjectEnum.user, obj.id, obj) as User;

    const result = await this.table_api.insertOrReplaceEntity<User>(this.tables.app_user, obj);
    return Promise.resolve(result);
  }

  public async deleteUser(id: number): Promise<any> {
    const result = await this.table_api.deleteEntity(this.tables.app_user, id.toString());
    return Promise.resolve(result);
  }

  public async getVenues(): Promise<Venue[]> {
    //const storage = new StorageHelper(this.blobApi);
    //let results = await storage.getObjects(StorageBrokerObjectEnum.venue) as Venue[];

    let results = await this.table_api.getEntities<Venue>(this.tables.venue);

    results = results.sort((a,b) => {
      return a.title>b.title ? 1 : -1;
    });

    return Promise.resolve(results);
  }

  public async getVenue(id: number): Promise<Venue> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.getObject(StorageBrokerObjectEnum.venue, id) as Venue;

    const result = await this.table_api.getEntity<Venue>(this.tables.venue, id.toString());
    return Promise.resolve(result);
  }

  public async storeVenue(obj: Venue): Promise<Venue> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.storeObject(StorageBrokerObjectEnum.venue, obj.id, obj) as Venue;

    const result = await this.table_api.insertOrReplaceEntity<Venue>(this.tables.venue, obj);
    return Promise.resolve(result);
  }

  public async getTeams(): Promise<Team[]> {
    //const storage = new StorageHelper(this.blobApi);
    //let results = await storage.getObjects(StorageBrokerObjectEnum.team) as Team[];

    const results = await this.table_api.getEntities<Team>(this.tables.team);
    return Promise.resolve(results);
  }

  public async getTeam(id: number): Promise<Team> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.getObject(StorageBrokerObjectEnum.team, id) as Team;

    const result = await this.table_api.getEntity<Team>(this.tables.team, id.toString());
    return Promise.resolve(result);
  }

  public async storeTeam(obj: Team): Promise<Team> {
    //const storage = new StorageHelper(this.blobApi);
    //const result = await storage.storeObject(StorageBrokerObjectEnum.team, obj.id, obj) as Team;

    const result = await this.table_api.insertOrReplaceEntity<Team>(this.tables.team, obj);
    return Promise.resolve(result);
  }



}
